<template>
  <div class="body add-params">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="102px"
        label-position="left"
        class="demo-form dialog-form dialog-form-inline"
      >
        <el-form-item label="方案名称：" prop="name">
          <el-input type="text" v-model.trim="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="线路名称：" prop="companyId">
          <lineSelect
            @getData="getLineData"
            :choosedLine="choosedLine"
            :isParams="true"
          ></lineSelect>
        </el-form-item>
        <el-form-item label="线路类型：" prop="lineType">
          <el-select
            v-model="addForm.lineType"
            disabled
            placeholder="请选择线路"
          >
            <el-option
              v-for="item in lineTypeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <p class="from-title form-title2">下行发车信息</p>
      <el-form
        ref="form1"
        :model="form"
        :inline="true"
        :rules="tableRules"
        class="dialog-form dialog-form-table plan-form"
      >
        <el-form-item label="下行运行时间：" prop="downTime">
          <el-time-picker
            is-range
            v-model="form.downTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm"
            value-format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
        <el-table :data="form.downList" class="deviceTable">
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="`downList.${scope.$index}.beginTime`"
                :rules="tableRules.beginTime"
              >
                <el-time-picker
                  v-model="scope.row.beginTime"
                  placeholder="选择时间"
                  format="HH:mm"
                  value-format="HH:mm"
                  :picker-options="{
                    selectableRange: beginTimeLimit('downTime'),
                  }"
                >
                </el-time-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="`downList.${scope.$index}.endTime`"
                :rules="tableRules.endTime"
              >
                <el-time-picker
                  v-model="scope.row.endTime"
                  placeholder="选择时间"
                  format="HH:mm"
                  value-format="HH:mm"
                  :picker-options="{
                    selectableRange: endTimeLimit(
                      'downTime',
                      'downList',
                      scope.$index
                    ),
                  }"
                >
                </el-time-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="intervalTime" label="发车间隔">
            <template slot-scope="scope">
              <el-form-item
                :prop="`downList.${scope.$index}.intervalTime`"
                :rules="tableRules.intervalTime"
              >
                <el-input-number
                  controls-position="right"
                  :min="0"
                  size="small"
                  v-model="scope.row.intervalTime"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="moveTime" label="运行时长">
            <template slot-scope="scope">
              <el-form-item
                :prop="`downList.${scope.$index}.moveTime`"
                :rules="tableRules.moveTime"
              >
                <el-input-number
                  controls-position="right"
                  :min="0"
                  size="small"
                  v-model="scope.row.moveTime"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="restTime" label="司机最小休息时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="`downList.${scope.$index}.restTime`"
                :rules="tableRules.restTime"
              >
                <el-input-number
                  controls-position="right"
                  size="small"
                  :min="0"
                  v-model="scope.row.restTime"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="upNum" label="车辆数">
            <template slot-scope="scope">
              <el-form-item
                :prop="`downList.${scope.$index}.downNum`"
                :rules="tableRules.downNum"
              >
                <el-input-number
                  controls-position="right"
                  size="small"
                  :min="0"
                  v-model="scope.row.downNum"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          @click="addDownGoing"
          class="addRow"
          size="small"
          type="info"
          plain
          >+</el-button
        >
        <el-button
          @click="delDownGoing"
          class="delRow"
          size="small"
          type="info"
          plain
          :disabled="form.downList.length <= 1"
          >-</el-button
        >
      </el-form>
      <p class="from-title form-title2" v-show="addForm.lineType !== 1">
        上行发车信息
      </p>
      <el-form
        ref="form2"
        :model="form"
        :inline="true"
        :rules="tableRules"
        v-show="addForm.lineType !== 1"
        class="dialog-form dialog-form-table plan-form"
      >
        <el-form-item label="上行运行时间：" prop="upTime">
          <el-time-picker
            is-range
            v-model="form.upTime"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm"
            value-format="HH:mm"
          >
          </el-time-picker>
        </el-form-item>
        <el-table :data="form.upList" class="deviceTable">
          <el-table-column
            type="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column label="开始时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="`upList.${scope.$index}.beginTime`"
                :rules="tableRules1.beginTime"
              >
                <el-time-picker
                  v-model="scope.row.beginTime"
                  placeholder="选择时间"
                  format="HH:mm"
                  value-format="HH:mm"
                  :picker-options="{
                    selectableRange: beginTimeLimit('upTime'),
                  }"
                >
                </el-time-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="`upList.${scope.$index}.endTime`"
                :rules="tableRules1.endTime"
              >
                <el-time-picker
                  v-model="scope.row.endTime"
                  placeholder="选择时间"
                  format="HH:mm"
                  value-format="HH:mm"
                  :picker-options="{
                    selectableRange: endTimeLimit(
                      'upTime',
                      'upList',
                      scope.$index
                    ),
                  }"
                >
                </el-time-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="intervalTime" label="发车间隔">
            <template slot-scope="scope">
              <el-form-item
                :prop="`upList.${scope.$index}.intervalTime`"
                :rules="tableRules.intervalTime"
              >
                <el-input-number
                  controls-position="right"
                  size="small"
                  :min="0"
                  v-model="scope.row.intervalTime"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="moveTime" label="运行时长">
            <template slot-scope="scope">
              <el-form-item
                :prop="`upList.${scope.$index}.moveTime`"
                :rules="tableRules.moveTime"
              >
                <el-input-number
                  controls-position="right"
                  :min="0"
                  size="small"
                  v-model="scope.row.moveTime"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="restTime" label="司机最小休息时间">
            <template slot-scope="scope">
              <el-form-item
                :prop="`upList.${scope.$index}.restTime`"
                :rules="tableRules.restTime"
              >
                <el-input-number
                  controls-position="right"
                  size="small"
                  :min="0"
                  v-model="scope.row.restTime"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="upNum" label="车辆数">
            <template slot-scope="scope">
              <el-form-item
                :prop="`upList.${scope.$index}.upNum`"
                :rules="tableRules.upNum"
              >
                <el-input-number
                  controls-position="right"
                  :min="0"
                  size="small"
                  v-model="scope.row.upNum"
                ></el-input-number>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          @click="addUpGoing"
          class="addRow"
          size="small"
          type="info"
          plain
          >+</el-button
        >
        <el-button
          @click="delUpGoing"
          class="delRow"
          size="small"
          type="info"
          plain
          :disabled="form.upList.length <= 1"
          >-</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  saveListPlan,
  queryListPlanById
} from '@/api/lib/bus-api.js'
import lineSelect from '@/components/lineSelect/lineSelect.vue'
import { checkTimeEqualLimit } from '@/common/utils/index'

export default {
  components: {
    lineSelect
  },
  props: {
    itemId: {
      type: [String, Number],
      required: true
    }
  },


  data () {
    let validateTime = (rule, value, callback) => {
      if (!this.form.downTime) {
        callback(new Error(`请先选择下行运行时间`))
      } else {
        let dateTime = this.form.downTime
        let startTime = this.returnTime(dateTime[0])
        let endTime = this.returnTime(dateTime[1])
        if (!checkTimeEqualLimit(startTime, this.returnTime(value))) {
          callback(new Error('时间必须在运行时间范围内'))
        } else if (!checkTimeEqualLimit(this.returnTime(value), endTime)) {
          callback(new Error('时间必须在运行时间范围内'))
        } else {
          callback()
        }
      }
    }

    let validateTime1 = (rule, value, callback) => {
      if (!this.form.upTime) {
        callback(new Error(`请先选择上行运行时间`))
      } else {
        let dateTime = this.form.upTime
        let startTime = this.returnTime(dateTime[0])
        let endTime = this.returnTime(dateTime[1])
        if (!checkTimeEqualLimit(startTime, this.returnTime(value))) {
          callback(new Error('时间必须在运行时间范围内'))
        } else if (!checkTimeEqualLimit(this.returnTime(value), endTime)) {
          callback(new Error('时间必须在运行时间范围内'))
        } else {
          callback()
        }
      }
    }
    return {
      day: "2021-03-10",
      addForm: {
        name: null,
        lineType: null,
        companyId: null,
      },
      lineTypeList: [
        { dictValue: "单向环线", dictCode: 1 },
        { dictValue: "双向线路", dictCode: 2 },
        { dictValue: "双向环线", dictCode: 3 }
      ],
      form: {
        upTime: null,
        downTime: null,
        upList: [{
          moveType: "1",
          beginTime: '',
          endTime: '',
          intervalTime: '',
          moveTime: '',
          restTime: '',
          upNum: '',
        }],
        downList: [{
          moveType: "2",
          beginTime: '',
          endTime: '',
          intervalTime: '',
          moveTime: '',
          restTime: '',
          downNum: '',
        }]
      },
      rules: {
        name: [{ required: true, message: "请输入方案名称", trigger: "blur" }],
        lineType: [{ required: true, message: "请选择线路类型", trigger: "blur" }],
        companyId: [{ required: true, message: "请选择线路", trigger: "blur" }],
      },
      loading: false,
      tableRules: {
        upTime: [{ required: true, message: '请选择上行时间', trigger: 'change' },],
        downTime: [{ required: true, message: '请选择下行时间', trigger: 'change' }],
        beginTime: [
          { required: true, message: '请选择时间', trigger: 'change' },
          { validator: validateTime, trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择时间', trigger: 'change' },
          { validator: validateTime, trigger: 'change' }
        ],
        intervalTime: [{ required: true, message: '请选择发车间隔', trigger: 'change' }],
        moveTime: [{ required: true, message: '请选择运营时长', trigger: 'change' }],
        restTime: [{ required: true, message: '请选择休息时间', trigger: 'change' }],
        downNum: [{ required: true, message: '请选择下行数量', trigger: 'change' }],
        upNum: [{ required: true, message: '请选择上行数量', trigger: 'change' }],
      },
      tableRules1: {
        beginTime: [
          { required: true, message: '请选择时间', trigger: 'change' },
          { validator: validateTime1, trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择时间', trigger: 'change' },
          { validator: validateTime1, trigger: 'change' }
        ],
      },
      choosedLine: null,

    };
  },

  methods: {
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$refs.form1.validate((valid) => {
            if (valid) {
              let operateTimeList = []
              let upTime = null
              if (this.addForm.lineType !== 1) {
                this.$refs.form2.validate((valid) => {
                  if (valid) {
                    this.$emit('onLoading', true)
                    operateTimeList = [...this.form.upList]
                    upTime = this.formatRangeTime(this.form.upTime)
                    this.saveListPlan(operateTimeList, upTime)
                  }
                })

              } else {
                this.$emit('onLoading', true)
                this.saveListPlan(operateTimeList, upTime)
              }
            }
          })
        } else {
          return false
        }
      })
    },
    // 模糊搜索线路
    getLineData (value, type) {
      this.addForm.companyId = value
      this.addForm.lineType = type
    },
    // 新增/修改
    saveListPlan (operateTimeList, upTime) {
      let downList = [...this.form.downList]
      const data = {
        operate: { ...this.addForm },
        operateTimeList: operateTimeList.concat(downList)
      }
      data.operate.downTime = this.formatRangeTime(this.form.downTime)
      data.operate.upTime = upTime
      let message = this.isEdit ? "修改成功" : "新增成功"
      if (this.isEdit) {
        data.operate.id = this.addForm.id
      }
      saveListPlan(data).then((res) => {
        if (res.code === 1000) {
          this.$message.success(message)
          this.clsoeDia(res.code === 1000)
        } else {
          this.$message.error(res.msg)
          this.$emit('onLoading', false)
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    /* 添加上行行 */
    addUpGoing () {
      this.onAddList("upList")
    },

    /* 添加下行行 */
    addDownGoing () {
      this.onAddList("downList")
    },
    // 添加列表
    onAddList (param) {
      let data = {
        moveType: "2",
        beginTime: '',
        endTime: '',
        intervalTime: '',
        moveTime: '',
        restTime: '',
        downNum: '',
      }
      if (this.form[param].length > 0) {
        if (this.form[param][this.form[param].length - 1].endTime) {
          data.beginTime = this.form[param][this.form[param].length - 1].endTime
        }
      }
      if (param == "upList") {
        data.moveType = "1"
        data.upNum = null
        delete data.downNum
      }
      this.form[param].push({ ...data });
    },

    /* 删除上行行 */
    delUpGoing () {
      this.form.upList.pop();
    },

    /* 删除下行行 */
    delDownGoing () {
      this.form.downList.pop();
    },
    /**获取时间 */
    returnTime (time) {
      return `${this.day} ${time}:00`
    },
    /**保存时 上下行运行时间*/
    formatRangeTime (time) {
      return `${time[0]}-${time[1]}`
    },

    /**获取时间限制范围 */
    beginTimeLimit (name) {
      let dateTime = this.form[name]
      let beginTime = '00:00:00'
      let endTime = '23:59:59'
      if (dateTime) {
        beginTime = `${dateTime[0]}:00`
        endTime = `${dateTime[1]}:00`
      }
      return `${beginTime} - ${endTime}`
    },
    /**获取时间限制范围 */
    endTimeLimit (name, list, index) {
      let dateTime = this.form[name]
      let startTime = this.form[list][index].beginTime
      let beginTime = '00:00:00'
      let endTime = '23:59:59'
      if (dateTime) {
        beginTime = `${dateTime[0]}:00`
        endTime = `${dateTime[1]}:00`
      }
      if (startTime) {
        beginTime = `${startTime}:00`
      }
      return `${beginTime} - ${endTime}`
    }

  },

  created () {
  },
  mounted () {
    if (this.itemId) {
      queryListPlanById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data.operate }
          this.choosedLine = this.addForm.companyName
          if (this.addForm.downTime) {
            this.form.downTime = this.addForm.downTime.split("-")
          }
          if (this.addForm.upTime) {
            this.form.upTime = this.addForm.upTime.split("-")
          }
          this.form.downList = []
          this.form.upList = []
          res.data.operateTimeList.map(item => {
            delete item.insertTime
            delete item.updateTime
            if (item.moveType == "1") {
              delete item.downNum
              this.form.upList.push(item)
            } else {
              delete item.upNum
              this.form.downList.push(item)
            }
          })
          delete this.addForm.companyName
          delete this.addForm.downTime
          delete this.addForm.upTime
          delete this.addForm.insertTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
      })
      this.isEdit = true
    } else {
      this.isEdit = false
    }
  }
};
</script>

<style lang="scss" scoped>
.add-params {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin-bottom: 10px;
    }
    .form-title2 {
      margin: 0;
      display: flex;
      justify-content: space-between;
    }
    .form-title3 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .addRow {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -1px;
      padding: 0px;
      height: 26px;
      line-height: 26px;
      width: 40px;
      font-size: 18px;
      border-top: none;
    }
    .delRow {
      position: absolute;
      left: calc(50% + 40px);
      transform: translateX(-50%);

      margin-top: -1px;
      padding: 0px;
      height: 26px;
      line-height: 26px;
      width: 40px;
      font-size: 18px;
      border-top: none;
    }
  }
}
</style>
