<template>
  <div class="params-management" ref="paramsManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddParams"
        v-if="nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="线路名称：">
          <lineSelectMult @getData="getLineData"></lineSelectMult>
        </el-form-item>

        <el-form-item label="方案名称：">
          <el-input
            v-model="form.keyWord"
            clearable
            placeholder="请输入方案名称"
          >
          </el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            @click="onSearch"
            :loading="searchLoading"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="name" label="方案名称"></el-table-column>
      <el-table-column prop="companyName" label="线路名称"></el-table-column>
      <el-table-column prop="lineType" label="线路类型">
        <template slot-scope="scope">
          {{ lineTypeObj[scope.row.lineType] }}
        </template>
      </el-table-column>
      <el-table-column prop="upTime" label="上行时间"></el-table-column>
      <el-table-column prop="downTime" label="下行时间"> </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateParams(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteParams(scope.row)"
            v-if="nowMenuList.indexOf('删除') >= 0"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <!-- 新增||编辑 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="70%"
      custom-class="add-params-dialog"
    >
      <addParams
        ref="addParams"
        :itemId="itemId"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>

import addParams from './components/addParams'
import lineSelectMult from '@/components/lineSelect/lineSelectMult.vue'
import { mapGetters } from 'vuex'

import {
  getListPlanList,
  deleteListPlan,
} from '@/api/lib/bus-api.js'


export default {
  name: 'paramsManagement',
  components: {
    addParams,
    lineSelectMult
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        keyWord: null,
        companyIds: null,
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      title: '',
      lineTypeObj: { 1: "单向环线", 2: "双向线路", 3: "双向环线" },
      dialogVisible: false,
      searchLoading: false,
      editLoading: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    }),

  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.paramsManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },

    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getListByField () {
      let data = { ...this.form }
      getListPlanList(data)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData =
              res.data.list && res.data.list.length > 0
                ? res.data.list
                : []
            this.total = res.data.total
            if (!res.data.list.length && this.form.currentPage !== 1) {
              // 如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
              this.form.currentPage -= 1
              this.onSearch()
            }

          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },

    // 模糊搜索线路
    getLineData (value) {
      this.form.companyIds = value
    },
    // 新增
    onAddParams () {
      this.title = '新增运营参数'
      this.itemId = ''
      this.dialogVisible = true
    },
    // 编辑
    onUpdateParams (row) {
      this.title = '修改运营参数'
      this.itemId = row.id
      this.dialogVisible = true
    },
    //  删除
    onDeleteParams (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将删除运营参数 '),
          h('span', { style: 'color: #ed6a0c' }, row.name),
          h('span', null, '  ，是否删除？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteListPlan(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.onSearch()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },

    // 关闭弹窗
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  保存
    onSave () {
      this.$refs.addParams.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },

  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.params-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-bus {
  .params-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>

